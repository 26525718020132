export default [
  {
    header: "Takeaway",
  },
  {
    title: "Dashboard",
    route: "takeaway-b",
    icon: "HomeIcon",
  },
  {
    title: "Shop",
    icon: "ShoppingBagIcon",
    // tag: '0',
    // tagVariant: 'light-warning',
    children: [
      {
        title: "Orders",
        route: "takeaway-shop-orders",
      },
      {
        title: "Customers",
        route: "takeaway-shop-customers",
      },
      {
        title: "Coupons",
        route: "takeaway-shop-coupons",
      },
    ],
  },
  {
    title: "Menu",
    icon: "PackageIcon",
    children: [
      {
        title: "Products",
        route: "takeaway-products-all",
      },
      {
        title: "Categories",
        route: "takeaway-products-categories",
      },
      {
        title: "Attributes",
        route: "takeaway-products-attributes",
      },
      {
        title: "Allergies",
        route: "takeaway-products-allergies",
      },
      {
        title: "Tags",
        route: "takeaway-products-tags",
      },
      {
        title: "VAT Rates",
        route: "vat-rates",
      },
    ],
  },
  {
    title: "Reporting",
    icon: "PieChartIcon",
    // tag: '0',
    // tagVariant: 'light-warning',
    children: [
      {
        title: "Order Reports",
        route: "takeaway-reporting-reports",
      },
    ],
  },
  {
    title: "Pages",
    icon: "BookIcon",
    // tag: '0',
    // tagVariant: 'light-warning',
    children: [
      {
        title: "About Us",
        route: "takeaway-pages-about",
      },
      {
        title: "Terms & Conditions",
        route: "takeaway-pages-terms",
      },
      {
        title: "Privacy Policy",
        route: "takeaway-pages-privacy",
      },
    ],
  },
  // {
  //   title: 'Billing',
  //   icon: 'PieChartIcon',
  //   // tag: '0',
  //   // tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'Transactions',
  //       route: 'takeaway-reporting-transactions',
  //     }
  //   ],
  // },
  {
    title: "Tools",
    icon: "ToolIcon",
    children: [
      {
        title: "Just Eat Importer",
        route: "takeaway-tools-just-eat-importer",
      },
    ],
  },

  {
    title: "Settings",
    route: "takeaway-settings",
    icon: "SettingsIcon",
  },
];
