import takeaway from './takeaway';
import general from './general';

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, requireAuth } from '@/auth/utils'



const userData = getUserData();
const userPermissions = userData ? userData.permissions : [];



const routes = [ ];

  const hasTakeawayPermission = userPermissions.some(permission => permission.permission_key === 'takeaway');
  const hasBookingPermission = userPermissions.some(permission => permission.permission_key === 'booking');

  // if (hasTakeawayPermission) {
    routes.push(...takeaway);
  // }

  if (hasBookingPermission) {
// Booking Menu
  }
  routes.push(...general )
// Array of sections
export default routes;
