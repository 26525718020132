export default [
  {
    header: 'Other',
  },
  // {
  //   title: 'Activity Log',
  //   route: 'activity-log',
  //   icon: 'ActivityIcon',
  // },
  // {
  //   title: 'Support',
  //   route: 'support',
  //   icon: 'LifeBuoyIcon',
  // },
  {
    title: 'FAQ',
    route: 'faq',
    icon: 'HelpCircleIcon',
  },
  {
    title: 'Logout',
    route: 'logout',
    icon: 'LogOutIcon',
  },
]
